.swiper {
  width: 100%;
  margin: auto;

  &[data-testid='align-center'] {
    margin-top: auto;
  }

  .swiper-slide {
    text-align: center;
    font-size: 1.125rem;

    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    content: '';
    position: absolute;
    border-width: 0 15px 15px 15px;
    border-style: solid;
    border-color: transparent transparent #4f2a86 transparent;
  }

  .swiper-button-next:after {
    transform: rotate(90deg);
  }

  .swiper-button-prev:after {
    transform: rotate(270deg);
  }
}

#avatar {
  max-width: 100%;
  min-height: 250px;

  .swiper {
    min-height: 270px;

    @media screen and (max-width: 768px) {
      min-height: 250px;
      display: flex;
      align-items: center;
    }
  }

  .swiper-wrapper {
    display: flex;
    align-items: center;
  }

  .swiper-slide {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;

    &.swiper-slide-active {
      z-index: 2;
    }
  }
}
